import moment from 'moment'

export const GEN_DATE_FORMAT = 'MM/DD/YY'

export const formatItemDate = date => {
  try {
    if (!date) return ''
    const momentDate = moment.unix(date).utc()
    if (momentDate.isValid() && typeof date !== 'object') return momentDate.format('MM/DD/yyyy')
    return moment.utc(date).format('MM/DD/yyyy')
  } catch (err) {
    console.error(err)
    return ''
  }
}

export const formatDate = (date, FORMAT = GEN_DATE_FORMAT) => {
  try {
    if (!date) return ''
    return moment(date).format(FORMAT)
  } catch (err) {
    console.error(err)
    return ''
  }
}

export const getCurrentCreateDate = () => {
  const now = moment()
  return now.format()
}

export const getTimestamp = date => {
  return date && moment.utc(date).unix()
}

export const sortItemByNewest = (a, b) => {
  if (!a || !b) return 0;
  const aTimestamp = getTimestamp(a.publishedDate)
  const bTimestamp = getTimestamp(b.publishedDate)
  if (aTimestamp > bTimestamp) {
    return -1;
  } else if (aTimestamp < bTimestamp) {
    return 1;
  }
  return 0;
}

export const sortItemByOldest = (a, b) => {
  const aTimestamp = getTimestamp(a.publishedDate)
  const bTimestamp = getTimestamp(b.publishedDate)
  if (aTimestamp < bTimestamp) {
    return -1;
  } else if (aTimestamp > bTimestamp) {
    return 1;
  }
  return 0;
}

export const getApiDate = date => {
  return date && moment(date).format()
}
