import { get, post, patch } from 'utils/axios'
import { buildAccountPatchPayload } from './helpers'
import { extractProfilePictureUrl, processLogin, processUser, processAlert } from './model'
import { readFileAsArrayBufferAsync } from 'shared/utils/tools/filereader'

export const loginUser = async ({ name, password }) => {
  const url = '/user/login?_format=json'
  const payload = {
    name: name,
    pass: password
  }
  const res = await post(url, payload, { withCredentials: true })
  return processLogin(res)
}

export const logoutUser = async (logoutToken) => {
  const url = `/user/logout?_format=json&token=${logoutToken}`
  return await post(url, null, { withCredentials: true })
}

export const registerUser = async ({ name, mail, displayName }) => {
  // const url = '/user/register?_format=json'
  const url = '/subrequests?_format=json'
  const signUpPayload = {
    name: [{
      value: name
    }],
    mail: [{
      value: mail
    }],
    'field_display_name': [{
      value: displayName
    }]
  }
  const lostPasswordPayload = {
    mail
  }
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  let payload = [
    {
      requestId: "req-1",
      uri: "/user/register?_format=json",
      action: "create",
      body: JSON.stringify(signUpPayload),
      headers
    },
    {
      requestId: "req-2",
      waitFor: ["req-1"],
      uri: "/user/lost-password?_format=json",
      action: "create",
      body: JSON.stringify(lostPasswordPayload),
      headers
    }
  ]
  const res = await post(url, payload)
  return res
}

export const requestPassword = async ({ accountName }) => {
  const url = '/user/lost-password?_format=json'
  const payload = {
    mail: accountName
  }
  const res = await post(url, payload)
  return res
}

export const getUserById = async id => {
  const url = `/user/${id}?_format=json`
  const res = await get(url)
  return processUser(res)
}

export const getLoginStatus = async () => {
  const url = '/user/login_status?_format=json'
  const res = await get(url, { withCredentials: true })
  return res === 1
}

export const getLogoutToken = async () => {
  const url = '/session/logout/token'
  return await get(url, { withCredentials: true })
}

export const getSessionToken = () => {
  return get('/session/token')
}

export const updateUserAccount = async (user, data) => {
  const url = `/user/${user.id}?_format=json`
  const payload = buildAccountPatchPayload(data)
  const res = await patch(url, payload)
  const processed = processUser(res)
  if (data.picture.file) processed.avatar = await updateProfilePicture(user.uuid, data.picture.file)
  return processed
}

export const updateProfilePicture = async (userUuid, imageFile) => {
  try {
    const config = {
      headers: {
        'Content-Disposition': `filename="${imageFile.name}"`,
       'Content-Type': 'application/octet-stream'
      }
    }
    const url = `/jsonapi/user/user/${userUuid}/user_picture`
    const imageBinary = await readFileAsArrayBufferAsync(imageFile)
    const res = await post(url, imageBinary, config)
    return extractProfilePictureUrl(res)
  } catch (error) { }
}

export const resetPassword = async formData => {
  const url = '/user/lost-password-reset?_format=json'
  const payload = {
    name: formData.mail,
    "temp_pass": formData.password,
    "new_pass": formData.newPass
  }
  return post(url, payload)
}

export const getAlertBanner = async () => {
  const url = '/alert-banner?_format=json'
  const res = await get(url)
  return processAlert(res)
}

export const checkDuplicateUserName = async (name) => {
  const url_dev = `/api/api/get/username?name=${name}`
  const url_pro = `/api/get/username?name=${name}`
  const url = process.env.NODE_ENV === 'development' ? url_dev : url_pro;
  const res = await get(url);
  return res;
}