import { post } from 'utils/axios'
import { fields } from 'features/Search/helpers/fields'
import moment from 'moment'
import cityPrefecture from 'shared/constants/cityPrefecture'
import { EN } from 'shared/constants'

const execute = async (query, variables) => {
  const url = '/graphql'
  const results = await post(url, { query, variables })
    .then(response => {
      return response
    })
    .catch(error => {
      console.log(error.response)
      return false
    })

  let data = results.data

  if (data) {
    if (data.errors) {
      return false
    }
    return data
  } else {
    return false
  }
}

const buildConditions = (values) => {
  let conditions = []
  const { media, partners, startDate, endDate } = fields
  const multiOptions = [media, partners]
  const AND = "AND"
  const OR = "OR"

  if (values.startDate || values.endDate) {
    let dateCondition = []

    if (values.startDate) {
      let startDatetime = values.startDate

      if (values.startTime) {
        startDatetime = `${values.startDate} ${values.startTime}`
      }

      let startDatetimeUnix = moment(startDatetime, "YYYY-MM-DD hh:mm a").unix()

      dateCondition.push({ operator: ">=", name: startDate.name, value: startDatetimeUnix })
    }

    if (values.endDate) {
      let endDatetime = values.endDate

      if (values.endTime) {
        endDatetime = `${values.endDate} ${values.endTime}`
      }

      let endDatetimeUnix = moment(endDatetime, "YYYY-MM-DD hh:mm a").unix()

      dateCondition.push({ operator: "<=", name: endDate.name, value: endDatetimeUnix })
    }

    conditions.push({
      conjunction: AND,
      conditions: dateCondition
    })
  }

  if (values.retweets) {
    conditions.push({
      conjunction: AND,
      conditions: [
        {operator: "=", name: "field_retweet", value: "TRUE"}
      ]
    })
  }

  if (values.language.length === 1) {
    const lang = values.language[0] === EN ? 'english' : 'japanese'
    conditions.push({
      conjunction: OR,
      conditions: [
        { operator: "<>", name: `field_${lang}_title`, value: '' },
        { operator: "<>", name: `field_${lang}_description`, value: '' }
      ]
    })
  }

  multiOptions.forEach(field => {
    if ((values[field.param] && values[field.param].length) || field.name === 'media_type_name') {
      let fieldCondition = []
      values[field.param].forEach(option =>
        fieldCondition.push({ operator: "=", name: field.name, value: option === 'testimonial' ? 'text' : option })
      )

      if (field.name === 'media_type_name') {
        if (values[field.param].length) {
          if (values.headlines) {
            fieldCondition.push({ operator: "=", name: "media_type_name", value: "headline" })
          }
        } else {
          if (!values.headlines) {
            fieldCondition.push({ operator: "<>", name: "media_type_name", value: "headline" })
          }
        }
      }

      conditions.push({
        conjunction: OR,
        conditions: fieldCondition
      })
    }
  })



  return {
    conjunction: AND,
    groups: [
      ...conditions,
      { conjunction: OR, conditions: [{operator: "<>", name: "nid", value: ''}]},
      ...(values.view === 'photo' ? [{ conjunction: OR, conditions: [{operator: "<>", name: "field_thumbnail_url", value: ''}]}] : [])
    ]
  }
}

const splitLongWord = longWordString => {
  if (!longWordString) return ''
  let result = longWordString
  const items = cityPrefecture.filter((cp) => {
    const addr = cp['compound city prefecture']
    return result.indexOf(addr) > -1;
  })
  items.forEach((cp) => {
    const addr = cp['compound city prefecture']
    result = result.replace(addr, ` ${cp['Prefecture']} ${cp['City']} `)
  })
  return result;
}


const parseSearchStringIntoArray = searchString => {
  let keywordArray = []
  const convertedString = searchString.replace(/[\u201d\u201c]/g, '"');
  const string = splitLongWord(convertedString)
  if (string) {
    let stringCopy = string.slice()
    const quoteCount = (string.match(/"/g) || []).length
    const quotePairCount = Math.floor(quoteCount / 2)
    if (quotePairCount >= 1) {
      for (let i = 0; i < quotePairCount; i++) {
        const firstQuote = stringCopy.indexOf('"')
        const secondQuote = stringCopy.indexOf('"', firstQuote + 1)
        const exactSearch = stringCopy.slice(firstQuote, secondQuote + 1)
        keywordArray.push(exactSearch.slice(1, -1).trim())
        stringCopy = stringCopy.replace(exactSearch, '')
      }
    }
    keywordArray = [...keywordArray, ...stringCopy.replace(/"/, '').replace('　', ' ').split(' ')].filter(v => v.trim().length)
  }
  return keywordArray
}

export {
  execute,
  buildConditions,
  parseSearchStringIntoArray
}